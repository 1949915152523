var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.table)?_c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('monte.stats_contrats')))])]),_c('div',{staticClass:"col-auto"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.horseStats",modifiers:{"horseStats":true}}],staticClass:"mb-0",attrs:{"variant":"primary"}},[_vm._v("Voir "),_c('font-awesome-icon',{attrs:{"icon":['fal', 'angle-down']}}),_c('font-awesome-icon',{attrs:{"icon":['fal', 'angle-up']}})],1)],1)]),(_vm.stats_loaded)?_c('b-collapse',{attrs:{"id":"horseStats"}},[_c('div',{staticClass:"row stats justify-content-center pt-4"},[_c('div',{staticClass:"col-xl-3 col-md-4 col-12 mb-3 mb-md-0"},[_c('b',[_vm._v(_vm._s(_vm.stats.brouillon))]),_vm._v(_vm._s(_vm.$t('monte.contrats_proposes_pas_valide')))]),_c('div',{staticClass:"col-xl-3 col-md-4 col-12 mb-3 mb-md-0"},[_c('b',[_vm._v(_vm._s(_vm.stats.valides_signes))]),_vm._v(_vm._s(_vm.$t('monte.contrats_valides_signes')))]),_c('div',{staticClass:"col-xl-3 col-md-4 col-12 "},[_c('b',[_vm._v(_vm._s(_vm.stats.without_contrats))]),_vm._v(_vm._s(_vm.$t('monte.jument_without_contract_but_liaison')))])])]):_c('div',[_c('LoadingSpinner')],1)],1),_c('div',{staticClass:"box",attrs:{"id":"stallion_monte"}},[_c('SeasonInput',{model:{value:(_vm.seasonSelected),callback:function ($$v) {_vm.seasonSelected=$$v},expression:"seasonSelected"}}),(_vm.seasonSelected != null)?[_c('CustomTable',{ref:"contractTable",attrs:{"id_table":"contract","primaryKey":"contract_id","externSlotColumns":_vm.extern_slot_columns,"hide_if_empty":true,"rawColumns":_vm.rawColumnNames,"hrefsRoutes":_vm.config_table_hrefs,"items":_vm.contracts,"columsAddBegin":['preview']},scopedSlots:_vm._u([{key:"custom-slot-cell(avenant.avenant_free)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_free)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.type_monte.contract_type_monte.contracttypemonte_label)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.avenant.type_monte.contract_type_monte.contracttypemonte_label))+" ")]}},{key:"custom-slot-cell(avenant.status.avenantstatus_label)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.avenant.status.avenantstatus_label))+" ")]}},{key:"custom-slot-cell(avenant.avenant_dn_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_dn_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.avenant_as_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_as_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.avenant_revue_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_revue_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.avenant_type_contrat)",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$t(data.avenant.avenant_type_contrat)))])]}},{key:"custom-slot-cell(formatted_invoices)",fn:function(ref){
var data = ref.data;
return _vm._l((data.formatted_invoices),function(invoice){return _c('p',{key:invoice.invoice_id},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.quickPreview(invoice.invoice_id)}}},[_vm._v(_vm._s(invoice.invoice_num))]),_vm._v(" ("+_vm._s(_vm.$t('invoice.invoice_balance'))+" : "+_vm._s(invoice.invoice_balance)+") ")])})}},{key:"custom-slot-cell(paillettes_date)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.paillettes_date)+" ")]}},{key:"custom-slot-cell(doses_date)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.doses_date)+" ")]}},{key:"custom-slot-cell(preview)",fn:function(ref){
var data = ref.data;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.quickContractPreview(data.contract_id)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'eye']}})],1)]}}],null,true)})]:_vm._e()],2)]):_c('div',[_c('ContractShutter',{attrs:{"contract_id":_vm.contract_id}})],1),_c('b-modal',{ref:"modelPreview",attrs:{"size":"xl","hide-footer":""},on:{"hidden":function($event){_vm.base64 = null}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("action.previsualiser"))+" ")]},proxy:true}])},[(_vm.base64 != null)?_c('iframe',{staticStyle:{"position":"relative"},attrs:{"src":'data:application/pdf;base64,' + _vm.base64,"height":"1000px","width":"100%"}}):_c('div',[_c('LoadingSpinner')],1)]),_c('b-modal',{ref:"modalEnvoie",attrs:{"hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("courrier_type.ajout"))+" ")]},proxy:true}])},[_c('e-select',{staticClass:"primary",attrs:{"id":"models","track-by":"model_label","label":"model_label","options":_vm.models,"searchable":true,"show-labels":false,"allow-empty":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.model_label))]}}]),model:{value:(_vm.model_selected),callback:function ($$v) {_vm.model_selected=$$v},expression:"model_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2),(_vm.model_selected)?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"mt-3",attrs:{"pill":"","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addModelCourrier($event)}}},[(_vm.processing)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'spinner'],"pulse":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("courrier_type.choisir")))],1)],1):_vm._e()],1),_c('ModalContractModifRapide',{ref:"modal_contract_modif_rapide"}),_c('ModalSendContract',{ref:"modal_send_contract",on:{"submit":_vm.refreshTable}}),_c('ModalActDGLot',{ref:"modal_act_dg_lot",on:{"submit":_vm.refreshTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }